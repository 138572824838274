import Dashboard from "./components/dashboard/Dashboard";

function App() {
  return (
    <>
      {/* <Login /> */}
      <Dashboard />
    </>
  );
}

export default App;
