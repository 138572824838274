export const class11_Nepali_Chapters = [
  "chapter1",
  "chapter2",
  "chapter3",
  "chapter4",
  "chapter5",
  "chapter6",
  "chapter7",
  "chapter8",
  "chapter9",
  "chapter1",
  "chapter2",
  "chapter3",
  "chapter7",
  "chapter8",
  "chapter9",
  "chapter1",
  "chapter2",
  "chapter3",
];

export const class11_Computer_Chapters = [
  "1. Computer System",
  "2. Number System & Conversion Boolen",
  "3.Computer Software & Operating",
  "4.Application pacakage",
  "5.Programing Comcepts & Logics",
  "6. WEB Technology-I",
  "7. Multimedia",
  "8. Information Security and Cyber Law",
];

export const class12_Computer_Chapters = [
  "1. Database Management System",
  "2. Networking",
  "3.Computer Software & Operating",
  "4.Application pacakage",
  "5.Programing Comcepts & Logics",
  "6. WEB Technology-I",
  "7. Multimedia",
  "8. Information Security and Cyber Law",
];
